import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { sv } from "date-fns/locale/sv";
import "./Table.css";

interface TeamTableRow {
  team_id: number;
  teamName: string;
  played: number;
  wins: number;
  draws: number;
  losses: number;
  points: number;
}

const Table: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [matches, setMatches] = useState<number>(5);
  const [tableData, setTableData] = useState<TeamTableRow[]>([]);

  console.log(isMobile);

  const fetchTable = async () => {
    const params = new URLSearchParams();
    if (fromDate) params.append("from", fromDate.toISOString().split("T")[0]);
    if (toDate) params.append("to", toDate.toISOString().split("T")[0]);
    if (matches) params.append("matches", matches.toString());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/matches/table?${params}`
      );
      const data = await response.json();
      setTableData(data.table);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <Box sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          Allsvensk Tabell
        </Typography>

        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <DatePicker
              label="Från datum"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <DatePicker
              label="Till datum"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <TextField
              label="Antal matcher"
              type="number"
              value={matches}
              onChange={(e) => setMatches(Number(e.target.value))}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Button
              className="fetch-table"
              variant="contained"
              onClick={fetchTable}
            >
              Hämta Tabell
            </Button>
          </Grid>
        </Grid>

        <Paper>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell>Lag</TableCell>
                {!isMobile && (
                  <>
                    <TableCell align="right">Matcher</TableCell>
                    <TableCell align="right">Vinster</TableCell>
                    <TableCell align="right">Oavgjorda</TableCell>
                    <TableCell align="right">Förluster</TableCell>
                  </>
                )}
                <TableCell align="right">Poäng</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.teamName}>
                  <TableCell>{row.teamName}</TableCell>
                  {!isMobile && (
                    <>
                      <TableCell align="right">{row.played}</TableCell>
                      <TableCell align="right">{row.wins}</TableCell>
                      <TableCell align="right">{row.draws}</TableCell>
                      <TableCell align="right">{row.losses}</TableCell>
                    </>
                  )}
                  <TableCell align="right">{row.points}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </Paper>
      </Box>
    </LocalizationProvider>
  );
};

export default Table;
