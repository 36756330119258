import React from 'react';
import Auth from './FortnoxAuth';
import OrdersTable from './OrdersTable';

const Admin: React.FC = () => {
  return (
    <div>
      <h1>Admin Panel</h1>
      <h2>Här kan du hantera ordrar och utföra andra administrativa uppgifter.</h2>
      <Auth />
      <OrdersTable />
    </div>
  );
};

export default Admin;