import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

export enum Status {
  PENDING,
  LOADING,
  INITIALIZED,
  SUCCESS,
  CANCELED,
}

interface CheckoutContextProps {
  status: Status;
  onSubmit: (data: any) => Promise<void>;
  methods: UseFormReturn<any>;
}

const CheckoutContext = createContext<CheckoutContextProps | undefined>(
  undefined
);

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return context;
};

interface Props {
  children: ReactNode;
}

export const CheckoutProvider: React.FC<Props> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [swishToken, setSwishToken] = useState("");
  const [swishId, setSwishId] = useState("");

  const maxAttempts = 120;
  const intervalIdRef = useRef<null | NodeJS.Timeout>(null);
  const attemptsRef = useRef(0);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const methods = useForm({
    defaultValues: {
      frequency: 60,
      number_of_messages: 10,
      sort_order: "random",
    },
  });
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState<any>(null);
  const [numberOfWins, setNumberOfWins] = useState(0);

  const verifyMatches = async (data: any) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/matches/count-wins`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          sender_team_id: data.sender_team_id,
          receiver_team_id: data.receiver_team_id,
        }),
      }
    );
    const result = await response.json();
    setNumberOfWins(result.count);
    return result.count >= data.number_of_messages;
  };

  const handlePlaceOrder = async (data: any) => {
    await fetch(`${process.env.REACT_APP_API_URL}/orders/place-order`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...data }),
    })
      .then((response) => response.json())
      .then(async ({ image, swish_payment_request, token }) => {
        setStatus(Status.INITIALIZED);
        setQrCode(image);
        setSwishToken(token);
        setModalOpen(true);
        setSwishId(swish_payment_request);
      });
  };

  const onSubmit = async (data: any) => {
    if (data.number_of_messages) {
      setStatus(Status.LOADING);
    }
    if (!data.number_of_messages) {
      methods.setError("number_of_messages", {
        type: "manual",
        message: "Please select the number of messages.",
      });
    } else {
      methods.clearErrors("number_of_messages");

      const hasEnoughMatches = await verifyMatches(data);
      if (!hasEnoughMatches) {
        setOrderData(data);
        setConfirmationOpen(true);
        setStatus(Status.PENDING);
        return;
      }

      await handlePlaceOrder(data);
    }
  };

  useEffect(() => {
    if (status === Status.INITIALIZED && swishId) {
      intervalIdRef.current = setInterval(() => {
        attemptsRef.current += 1; // Increment attempts using useRef
        fetch(`${process.env.REACT_APP_API_URL}/orders/check-swish/${swishId}`)
          .then((data) => data.json())
          .then((status) => {
            if (status === "PAID") {
              setStatus(Status.SUCCESS);
              clearInterval(intervalIdRef.current!);
            } else if (attemptsRef.current >= maxAttempts) {
              setStatus(Status.CANCELED);
              clearInterval(intervalIdRef.current!);
              setModalOpen(false);
            }
          })
          .catch((error) => {
            console.error("Error checking payment status:", error);
            clearInterval(intervalIdRef.current!);
            setStatus(Status.CANCELED);
            setModalOpen(false);
          });
      }, 1000); // Interval set to 1 second
    }

    if (status === Status.SUCCESS) {
      clearInterval(intervalIdRef.current!);
      navigate(`/confirmation?swish_ref=${swishId}`);
    }

    if (status === Status.CANCELED) {
      clearInterval(intervalIdRef.current!);
      // Optionally, you can handle the cancellation case here, e.g., show a message to the user
    }

    // Cleanup function to clear interval if component unmounts or status changes
    return () => clearInterval(intervalIdRef.current!);
  }, [navigate, status, swishId]);

  return (
    <CheckoutContext.Provider value={{ status, onSubmit, methods }}>
      <FormProvider {...methods}>
        {children}
        <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
          <div
            style={{ padding: "1em", display: "flex", flexDirection: "column", textAlign: "center" }}
          >
            Skanna QR-koden: <br />
            <img src={`data:image/png;base64,${qrCode}`} alt="Swish QR kod" />
            <br />
            Eller
            <Button
              sx={{ mt: 2 }}
              color="primary"
              variant="contained"
              href={`swish://paymentrequest?token=${swishToken}&callbackurl=`}
            >
              Öppna Swish på denna enhet
            </Button>
          </div>
        </Dialog>
        <Dialog
          onClose={() => setConfirmationOpen(false)}
          open={confirmationOpen}
        >
          <DialogTitle>Inte tillräckligt med matcher</DialogTitle>
          <DialogContent>
            {numberOfWins === 0
              ? `Det finns inga vister för valda lag.`
              : `Det finns bara ${numberOfWins} vinster för valda lag. Det kommer
          alltså bara skickas ${numberOfWins} SMS. Vill du beställa ändå eller
          välja andra lag/ändra antalet SMS?`}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationOpen(false)} color="primary">
              Välj andra lag
            </Button>
            <Button
              variant="contained"
              disabled={numberOfWins === 0}
              onClick={async () => {
                setConfirmationOpen(false);
                if (orderData) {
                  await handlePlaceOrder(orderData);
                }
              }}
              color="primary"
            >
              Beställ ändå
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
