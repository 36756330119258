import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useFortnox } from '../../context/FortnoxContext';

const Auth: React.FC = () => {
  const clientId = process.env.REACT_APP_FORTNOX_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_FORTNOX_REDIRECT_URI;
  const state = 'somestate123';
  const scope = 'bookkeeping%20archive%20connectfile';

  const authUrl = `https://apps.fortnox.se/oauth-v1/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_type=code`;

  const { isAuthenticated } = useAuth();
  const { token } = useFortnox();

  if (isAuthenticated && token) {
    return null;
  }

  return (
    <Box textAlign="center" marginTop={2}>
      <Typography variant="h5" marginBottom={2}>
        Fortnox Auth
      </Typography>
      <Button variant="contained" color="primary" href={authUrl}>
        Log in with Fortnox
      </Button>
    </Box>
  );
};

export default Auth;