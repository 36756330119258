import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import PhoneInput from "../PhoneInput";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function UnsubscribeForm() {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/sms/unsubscribe/${data.phone_number.replace(/\D/g, "")}`
      );
      const result = await response.json();
      console.log(result)
      alert("Sändningen till ditt nummer har nu avslutats");
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      alert("Lyckades inte avsluta sändningen");
      setIsLoading(false);
    }
  };

  return (
    <Box p={5} boxSizing="border-box" maxWidth={400} width="90%" m="auto">
      <Card>
        <CardHeader
          title="Hoppsan!"
          subheader="Det verkar som att du har blivit fintad"
        />
        <CardContent>
          Ojojoj.. du verkar ha en vän som inte tycker som du. Här på Finten
          skickas det max 30 sms, beroende på hur snål din kompis har varit vid
          köptillfället. Nu har du två val. Du kan antingen hämnas, eller så kan
          du avsluta nuvarande sändning av SMS genom att mata in ditt
          telefonummer.
        </CardContent>
        <CardActions>
          <Button variant="contained" href="/#form">
            Hämnas
          </Button>
          <Button onClick={() => setOpen(true)}>Avprenumerera</Button>
        </CardActions>
      </Card>
      <Dialog fullWidth onClose={() => setOpen(false)} open={open}>
        <FormProvider {...methods}>
          <form style={{ marginTop: 20 }} onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Avprenumerera</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Är du verkligen säker? Det skickas bara ett fåtal meddelanden
                med matchresultat och allt är bara på skoj. <br /> <br />
                Om du redan bestämt sig så är det bara att fylla i ditt nummer i
                nedan formulär så kommer eventuella schemalagda SMS att tas
                bort. Vill du permanent blockera ditt nummer från framtida
                beställningar, skicka ett mail med ditt nummer till
                kontakt@finten.se
              </DialogContentText>
              <PhoneInput name="phone_number" />
              {errors.phone_number && <p>Ange giltigt mobilnummer.</p>}
            </DialogContent>
          </form>
        </FormProvider>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            variant="outlined"
          >
            Avregistrera
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default UnsubscribeForm;
